$dark: #323740;
$white: #fff;

$primary: #5B5693;
$primary-lighten-2: #CDACD8;
$primary-lighten-3: #fdf6ff;

$dimmed: #76839A;
$dimmed-lighten: #CDD5E2;

$grey: #AAAAAA;
$grey-lighten-1: #C4C4C4;
$grey-lighten-2: #DEDEDE;
$grey-lighten-3: #F8F8F8;
