.cke_chrome {
  border-radius: 5px;
  border: 0 !important;
}

.cke_inner {
  border-radius: 5px !important;
}

.cke_top {
  background: none !important;
  border-top: 1px #ddd solid !important;
  border-bottom-color: #ddd !important;
}

.cke_bottom {
  border-radius: 0 0 5px 5px !important;
  background: none !important;
  border-top: none !important;
  position: absolute !important;
  right: 0;
  bottom: 0;
}

.cke_resizer_ltr {
  margin-right: -6px !important;
}