@import './../settings';

.panel-rounded {
    background: #fff;
    border-radius: 4px;
    @include shadowDefault;
    margin-bottom: 25px;

    @media (max-width: $media-sm-width) {
        box-shadow: none;
        background: transparent;
        border-radius: 0;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px #ddd solid;

        &:first-of-type {
            padding-top: 25px;
            border-top: 1px #ddd solid;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &[class*="background"] {
            padding-bottom: 0 !important;

            .panel-header {
                padding-top: 25px !important;
            }
        }
    }

    &.background-success {
        @include gradientSuccess;
        @include shadowSuccess;
    }

    &.background-danger {
        @include gradientDanger;
        @include shadowDanger
    }

    &.background-secondary {
        @include gradientSecondary;
        @include shadowDefault;
    }

    &.with-icon {
        padding-left: 65px;
        min-height: 60px;

        .icon {
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 40px;

            @media (max-width: $media-sm-width) {
                font-size: 26px;
            }
        }
    }

    .panel-header {
        padding: 25px 20px 20px 20px;

        @media (max-width: $media-sm-width) {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }

        h2 {
            font-size: 3rem;
            font-weight: bold;
            margin: 0;
        }

        h3 {
            font-weight: bold;
            margin: 0;
        }

        .left {
            float: left;
        }

        .right {
            float: right;
        }

        *:last-child {
            margin-bottom: 0;
        }

        &:after {
            clear: both;
            display: block;
            content: ' ';
        }
    }

    .panel-body {
        padding: 0 20px 15px 20px;

        @media (max-width: $media-sm-width) {
            padding: 0 !important;
        }

        &--no-padding {
            padding: 0 !important;
        }

        &:first-child {
            padding-top: 20px;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    &.panel-rounded-no-header {
        .panel-body {
            padding-top: 20px;
        }
    }

    .panel-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.8);
        background-image: url(/assets/img/loader-dark.gif);
        background-repeat: no-repeat;
        background-position: center 50px;
        background-size: 110px 10px;
        z-index: 10;
    }
}

.panel {
    border: 0;
    background: none;
    box-shadow: none;

    .heading {
        padding: 5px 0;

        h3 {
            font-size: 14px;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .heading.gradient-violet {
        color: #fff;
        border: 0;
        padding: 15px;
        border-radius: 4px 4px 0 0;
    }

    .heading.gradient-violet + .body {
        border-radius: 0 0 5px 5px;
    }

    .body {
        padding: 15px;
        border-top: 0;
        border-radius: 5px;
        background: #fff;
        border: 1px #e5e5e5 solid;

        &.no-padding {
            padding: 0;
            border-radius: 0;
            border: 0;
        }

        .form-group:last-of-type {
            margin-bottom: 0;
        }
    }
}

.panel-a {
    background: #fff;
    margin: 0px -15px;
    border-top-left-radius: 10px;

    .panel-a-header {
        padding: 15px 25px;
        border-bottom: 1px #eee solid;
        background: #fafafa;
        border-top-left-radius: 15px;

        transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;

        h1 {
            font-size: 2em;
            font-weight: bold;
            margin: 4px 0 0;
            float: left;
            max-width: calc(100% - 250px);

            small {
                text-transform: none;
                font-size: 0.65em;
                color: rgb(170, 170, 170);
                font-weight: 500;
            }
        }

        .buttons {
            float: right;
            line-height: 32px;

            .button-label {
                font-size: 34px;
                vertical-align: top;
                line-height: 0;
                height: 34px;
                display: inline-block;

                &.success {
                    color: #82B13D;
                }
            }

            h3 {
                display: inline-block;
                vertical-align: middle;
                margin: 0 10px 0 0;
            }

            .button-label {
                font-size: 34px;
                vertical-align: top;
                line-height: 0;
                height: 34px;
                display: inline-block;

                &.success {
                    color: #82B13D;
                }
            }
        }

        &:before, &:after {
            content: " ";
            display: table;
            clear: both;
        }
    }

    .panel-a-body {
        padding: 25px;

        > .row {
            margin-left: -25px;
            margin-right: -25px;
            border-bottom: 1px #eee solid;
            display: flex;

            &.no-flex {
                display: block;
            }

            > div {
                padding: 25px 25px 15px 25px;

                border-right: 1px #eee solid;

                &:last-of-type {
                    border-right: 0;
                }

                > .panel-inner:last-of-type:not(.background) {
                    padding-bottom: 0;
                }
            }

            .panel-inner {
                margin-left: -25px;
                margin-right: -25px;

                padding: 25px 25px 15px 25px;

                &:first-of-type {
                    margin-top: -25px;
                }

                .heading, .header-b {
                    padding: 15px 25px;
                    margin: -25px -25px 15px -25px;

                    h3 {
                        margin: 0;
                        float: left;
                        line-height: 22px;
                    }

                    .buttons {
                        float: right;
                    }

                    &:after {
                        clear: both;
                        content: ' ';
                        display: block;
                    }
                }

                &.background {
                    overflow: hidden;

                    * {
                        z-index: 2;
                    }

                    & + .panel-inner {
                        border-top: 0;

                        &.background {
                            margin-top: 0;
                        }
                    }

                    .background-icon {
                        position: absolute;
                        opacity: 0.3;
                        top: 10px;
                        right: 25px;
                        font-size: 80px;
                        z-index: 1;
                    }
                }

                &.background-success + .background-success {
                    border-top: 1px #7db343 solid !important;
                }

                &.background-danger + .background-danger {
                    border-top: 1px #e53935 solid !important;
                }

                & + .panel-inner {
                    border-top: 1px #eee solid;
                    padding-top: 20px;

                    &.background {
                        border-top: 0;
                    }
                }
            }

            &:nth-of-type(2n) {
                background: #fafafa;
                border-bottom: 1px #eee solid;
            }

            &.no-background {
                background: none;
            }

            &.no-borders {
                > div {
                    border: 0;
                }
            }

            &:first-of-type {
                margin-top: -25px;
            }
            &:last-of-type {
                margin-bottom: -25px;
                border-bottom: 0;
            }
        }
    }
}

.header-panel-a-fixed {
    .panel-a-header {
        position: fixed;
        top: 0;
        right: 0;
        left: $sidebar-width;
        z-index: 10;
        border-bottom: 0;
        border-radius: 0;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    }

    #content {
        padding-top: 69px;
    }

    .default-layout.with-collapsed-sidebar {
        .panel-a-header {
            left: $sidebar-collapsed-width;
        }
    }
}

.header-panel-a-fixed.modal-on {
    .panel-a-header {
        opacity: 0;
    }
}

.stats-a {
    .inner {
        float: left;

        h5 {
            color: #999;
            margin: 0;
        }
        h3 {
            color: #5B5693;
            font-size: 24px;
            margin: 0;
        }
    }
    .icon {
        float: right;
    }
}
