@mixin gradientDanger {
  background: #fb6460;
  background: linear-gradient(to bottom, #fb6460, #e6413d);
}

@mixin gradientSuccess {
  background: #a7db4e;
  background: linear-gradient(to bottom, #a7db4e, #88c73f)
}

@mixin gradientInfo {
  background: #38a3ff;
  background: linear-gradient(to bottom, #38a3ff 0%,#3086d0 100%);
}

@mixin gradientWarning {
  background: #FFCB29;
  background: linear-gradient(to bottom, #FFCB29 0%,#FFC000 100%);
}

@mixin gradientPrimary {
  background: #8F8BBB;
  background: linear-gradient(45deg, #8F8BBB 0%,#B38BBB 100%);
}

@mixin gradientSecondary {
  background: #40454f;
  background: linear-gradient(to bottom, #40454f 0%,#25282d 100%);
}
