/*
 * Variables...
 */

$border-radius-base: 0;
$brand-primary: #2B5A84;
$active-icon-color: #6CC94D;
$btn-border-radius-base: 4px;
$btn-font-weight: 300;
$font-family-sans-serif: 'Open Sans', Helvetica, Arial, sans-serif !default;
$font-size-base: 14px;
$navbar-height: 65px;

/*
 * Bootstrap CSS...
 */
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

/**
 * Settings
 */
@import "settings";

/**
 * Elements
 */
@import "elements/buttons";
@import "elements/forms";
@import "elements/modals";
@import "elements/popups";
@import "elements/nav";
@import "elements/layouts";
@import "elements/panels";
@import "elements/tables";
@import "elements/table-advanced";
@import "elements/tooltips";
@import "elements/utils";
@import "elements/tabs";
@import "elements/charts";

@import "elements/ckeditor";
@import "elements/datepicker";
@import "elements/daterangepicker";

/**
 * Icons
 */
@import "icons/font-awesome.min";
@import "icons/pe-icon-7-stroke";
@import "icons/pe-icon-7-stroke-helper";

/*
 * Misc.
 */
html, body {
    height: 100%;
}

html.stop-scrolling, html.stop-scrolling body {
    overflow: hidden;
}

body {
    font-family: 'Open sans', Arial, sans;
    font-size: 12px;
    background-color: $main-dark-background;
    color: #282c34;
    overflow-y: scroll;
}

* {
    position: relative;
    outline: none !important;
}

img {
    max-width: 100%;
}

h1 {
    font-size: 24px;
    font-weight: 100;
    margin-top: 0;
}

h2 {
    font-size: 20px;
    font-weight: 700;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

hr {
    border-color: #e5e5e5;
}

a {
    color: #5B5693;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #5B5693;
    }
}

#app {
    min-width: 300px !important;
}

.loader {
    height: 0;
    background: url('/assets/img/loader-dark.gif') no-repeat center;
    background-size: 110px 10px;
    z-index: 5;
}

.loader.light {
    background: url('/assets/img/loader-light.gif');
}

.loader.active {
    height: 100px;
}

.loader-bar {
    width: 100%;
    height: 100px;
    background: url('/assets/img/loader-mini.gif') no-repeat center;
    background-size: 20px;
}

.text-default {
    color: #323740 !important;
}

.text-white {
    color: #fff !important;

    hr {
        border-color: rgba(255, 255, 255, 0.3);
    }
}

.text-primary {
    color: #5B5693 !important;
}

.text-success {
    color: #456614 !important;
}

.text-danger {
    color: #a94442 !important;
}

.text-danger-light {
    color: #ee534f !important;
}

.text-dim {
    color: #ddd !important;
}

.text-bold {
    font-weight: bold;
}

.text-crossed {
    text-decoration: line-through !important;
    opacity: 0.4;
}

.text-small {
    font-size: 0.8em;
    color: #aaa;
}

.display-block {
    display: block;
}

.pe-stroke:after {
    content: ' ';
    display: block;
    width: 1px;
    height: 150%;
    position: absolute;
    top: -25%;
    left: 50%;
    background: #fff;
    transform: rotate(45deg);
}

.background-primary {
    background: #8F8BBB;
    background: -moz-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
    background: -webkit-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
    background: linear-gradient(to bottom, #8F8BBB 0%, #5B5693 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8F8BBB', endColorstr='#5B5693', GradientType=0);

    * {
        color: #fff;
    }
}

.background-secondary {
    background: #31353c;

    * {
        color: #fff;
    }
}

.background-default {
    background: #fafafa;

    * {
        color: #323740;
    }
}

.background-success {
    background: #9ccc66;

    *, .text-success {
        color: #456614;
    }
}

.background-danger {
    background: #ee534f;
    *, .text-danger {
        color: #fff;
    }
}

.background-warning {
    background: #FFCB29;
    *, .text-warning {
        color: #846300;
    }
}

.gradient-violet {
    color: #fff;
    background: #8F8BBB;
    background: -moz-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
    background: -webkit-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
    background: linear-gradient(to bottom, #8F8BBB 0%, #5B5693 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8F8BBB', endColorstr='#5B5693', GradientType=0);
}

.alert {
    border-radius: 5px;
    padding: 15px;

    i.fa {
        margin-right: 7px;
        line-height: 16px;
    }
}

.info {
    padding: 25px 25px 20px 25px;
    margin: -25px;
    min-height: 100px;

    .fa {
        position: absolute;
        top: 15px;
        right: 30px;
        opacity: 0.3;
        font-size: 70px;
    }

    h3 {
        font-weight: bold;
        margin: 0 0 15px 0;
    }

    a {
        opacity: 0.75;

        &:hover {
            color: #fff;
            opacity: 1;
        }
    }
}

.animated, .animated-full, .animated-full > * {
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
}

.pagination-a {
    margin: 13px 0 0 0;
    padding: 0;
    border-radius: 15px;
    list-style: none;

    li {
        font-weight: bold;
        float: left;
        padding: 0;
        margin: 0;

        &:first-of-type {
            a, span {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            a {
                border-left: 1px #ddd solid;
            }
        }

        &:last-of-type {
            a, span {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
        }

        a, span {
            display: block;
            padding: 6px 11px;
        }

        span {
            padding-top: 7px;
            padding-bottom: 7px;
        }

        a {
            cursor: pointer;
            background: #f8f8f8;
            border: 1px #ddd solid;
            border-left: 0;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                background: #eee;
            }
        }
    }
}

.value-positive {
    font-weight: bold;
    color: #78ba54;
}

.value-negative {
    font-weight: bold;
    color: #a94442;
}

.jumbotron {
    background: transparent;
    box-shadow: none;

    h2 {
        font-size: 48px;
        font-weight: 300;
    }

    h3 {
        font-size: 36px;
        font-weight: 300;
    }

    p {
        font-size: 18px;
    }
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

#main-loader {
    position: fixed;
    top: -110%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1) url('/assets/img/loader-dark.gif') no-repeat center;
    background-size: 110px 10px;
    z-index: 1000;
    opacity: 0;

    .text {
        width: 80%;
        height: 30px;
        position: absolute;
        padding-top: 40px;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -40%;
        text-align: center;
        font-weight: bold;
    }
}

#main-loader.on {
    top: 0;
    opacity: 1;
}

#app {
    height: 100%;
}

#root {
    min-height: 500px;
    height: 100%;
    z-index: 1;

    &.default-layout-old {
        width: calc(100% - #{$sidebar-width});
        float: right;
    }
}

.heading-a {
    padding-bottom: 10px;

    h1 {
        margin-top: 5px;
        margin-bottom: 0;
        float: left;

        small {
            text-transform: none;
            font-size: 60%;
            color: #aaa;
            font-weight: 100;
        }
    }

    .buttons {
        float: right;

        h3 {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 0;
        }

        .button-label {
            font-size: 34px;
            vertical-align: top;
            line-height: 0;
            height: 34px;
            display: inline-block;

            &.success {
                color: #82B13D;
            }
        }
    }
}

.heading-b {
    border-bottom: 1px #eee solid;
    padding-bottom: 20px;
    margin-bottom: 20px;

    > h1, > h2, > h3 {
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 20px;
        float: left;
    }

    .buttons {
        float: right;
    }

    &.filled {
        margin: -30px -30px 20px -30px;
        padding: 27px 30px;
        background: #eee;
    }

    &.no-border {
        border-bottom: 0;
        padding-bottom: 0;
    }

    &.no-margin {
        margin-bottom: 10px;
    }

    .helper {
        float: right;
        width: 160px;
    }

    &:after {
        clear: both;
        content: ' ';
        display: table;
    }
}

.heading-c {
    margin-bottom: 20px;
}

.sweet-alert.pin-confirmation {
    z-index: 990 !important;
}

.sweet-alert.pin-confirmation + .sweet-overlay {
    z-index: 980 !important;
}

.header-a-fixed {
    .heading-a {
        position: fixed;
        top: 0;
        right: 0;
        left: $sidebar-width;
        z-index: 10;
        padding: 8px 25px;
        background: #fff;
        border-bottom: 0;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    }

    #content {
        padding-top: 59px;
    }
}

#header {
    height: 46px;
}

#content {
    min-height: calc(100% - #{$header-height});
    background: #fff;
    border-top-left-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.6);
    z-index: 1;

    &.content-simple {
        background: #f7f8f8;
    }
}

.vertical-content {
    display: table;
    width: 100%;
    height: 100%;

    .vertical-content-child {
        display: table-cell;
        vertical-align: middle;
    }
}

@media (min-width: 1600px) {
    body {
        font-size: 14px;
    }
    #root {
        &.default-layout {
            width: calc(100% - #{$sidebar-xl-width});
        }

        &.with-collapsed-sidebar {
            width: calc(100% - #{$sidebar-collapsed-width});
        }
    }
    .header-panel-a-fixed .panel-a-header, .header-a-fixed .heading-a {
        left: $sidebar-xl-width;
    }
}

@media (max-width: 991px) {
    .login-page form {
        margin-top: 80px;
    }
}

@media (max-width: 400px) {
    .login-page form {
        margin-top: 50px;
    }
}

[v-cloak] {
    display: none;
}

.dl-horizontal.a {
    dt {
        text-align: left !important;
        padding: 2px 0;
    }
    dd {
        padding: 2px 0;
    }

    &.reverse-bold {
        dt {
            font-weight: normal;
        }
        dd {
            font-weight: bold;
        }
    }
}

@import "responsive";
