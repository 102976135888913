.tabs {
	nav {
		z-index: 2;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				padding: 0;
				margin: 0;

				a {
					display: block;
					padding: 10px 20px;
					border-radius: 5px 5px 0 0;
					border: 1px #eee solid;
					background: #eee;
					color: #aaa;
					font-weight: bold;
				}

				a:hover, a:focus {
					text-decoration: none;
				}
			}

			li.active {
				a {
					background: #fff;
					color: #4D535D;
					border-bottom-color: transparent;
				}
			}
		}
	}

	.tabs-content {
		border: 1px #eee solid;
		border-radius: 0 5px 5px 5px;
		margin-top: -1px;
		z-index: 1;

		.inner {
			padding: 20px;

			.tab-pane {
				display: none;

				.tab-module {

				}
			}
			.tab-pane.active {
				display: block;
			}
		}
	}
}

.tabs.horizontal {
	nav {
		width: 15%;
		float: left;

		ul {
			li {
				display: block;
				margin-bottom: 5px;

				a {
					padding: 15px 10px;
					border-radius: 5px 0 0 5px;
				}

				a:hover, a:focus {

				}
			}

			li.active {
				a {
					border-bottom: 1px #eee solid;
					border-right: 0;
				}
			}
		}
	}

	.tabs-content {
		width: 85%;
		float: right;
		margin-top: 0;
		left: -1px;

		.tab-pane {
			min-height: 150px;
		}
		.tab-pane.active {

		}
	}
}
.tabs.horizontal:after {
	clear: both;
	content: ' ';
	display: block;
}

.tab-permissions {
	padding-left: 230px;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px #eee solid;

	h3 {
		display: block;
		position: absolute;
		top: 5px;
		left: 0;
		margin: 0;
		font-size: 16px;
	}
}

.tab-permissions:last-of-type {
	margin-bottom: 0;
	border-bottom: 0;
}
