@media (max-width: 991px){
	#header {
		height: 40px;

		.buttons-nav-helper {
			display: none;
		}

		.nav-helper {
			ul {
				li {
					display: none;

					&.logout-nav-btn {
						display: block;
					}

					a {
						height: 40px;
    					padding: 9px 7px;
					}
				}
			}
		}
	}

	#sidebar {
		position: absolute;
	    top: 0;
	    width: 300px;
		max-width: 100%;
	    height: calc(100% - 40px);
	    left: -100%;
	    background: #31353c;
	    z-index: 2;
	    overflow: scroll;

	    .inner {

			.logo {
				a {
					padding-top: 10px;

					span {
						display: none;
					}
				}
			}
		}
	}

	html.sidebar-active {
		overflow: hidden;

		body {
			overflow: hidden;
		}

		#sidebar {
			left: 0;
		}
	}

	#root {
		margin-left: 0;
		z-index: 1;

        &.default-layout {
            float: none;
            width: 100%;
        }

		#content {
			border-radius: 0;
		}
	}

	.header-panel-a-fixed {
		#content {
			padding-top: 15px;
		}

		.panel-a-header {
			position: relative;
			top: auto;
			left: auto;
			box-shadow: none;
			border-bottom: 1px #eee solid;
			padding: 10px;
		}
	}

	.panel-a .panel-a-header {
		padding: 10px;

		.buttons {
			.btn {
				font-size: 10px;
				padding: 9px 22px 8px 22px;
			}
		}
	}

	.btn {
		margin-top: 3px;
	}
}

@media (max-width: 767px){
	#main-loader {
		position: absolute;
	}

	.panel-a {
		.panel-a-body {
			> .row {
				display: block;

				> div {
					border-bottom: 1px #eee solid;
					padding: 10px;

					&:last-of-type {
						border-bottom: 0;
					}
				}
			}
		}

		.panel-a-header {
			h1 {
				float: none;

				small {
					display: none;
				}
			}

			.buttons {
				float: none;
				margin-top: 10px;

				.daterangepicker-input.daterangepicker-input-header {
					float: none;
					text-align: left;
					border: 1px #ddd solid;
					border-radius: 25px;
					padding: 5px 10px;
				    font-size: 1.2em;
				    font-weight: normal;
				}
			}
		}
	}

	.info {
		padding: 15px 10px 7px 10px;
		margin: -10px;
	}

	.daterangepicker.dropdown-menu {
		left: 20px !important;
	}

	.daterangepicker .calendar, .daterangepicker .ranges {
		max-width: 100%;
		width: 100%;
	}

	.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .calendar {
		margin: 4px 0;
	}
}

@media (max-width: 767px){
	#sidebar {
	    width: 100%;
	    left: -100%;
	}
}
