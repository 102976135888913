.table-advanced {
  &__table {
    width: 100%;

    thead {
      tr {
        border-bottom: 2px $dimmed-lighten solid;

        th {
          color: $dimmed;
          text-transform: uppercase;
          line-height: 1em;
          padding: 20px 10px 15px 10px;

          &:first-of-type {
            padding-left: 20px;
          }

          &:last-of-type {
            padding-right: 20px;
          }

          small {
            display: block;
            color: $grey;
          }
        }
      }
    }

    tbody {
      tr {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        border-bottom: 1px $dimmed-lighten solid;
        transition: all 0.1s ease;
        background: transparent;

        &:last-of-type {
          border-bottom: 0;
        }

        &.odd {
          background: $grey-lighten-3;
        }

        &.error {
          td {
            background: #f2dede !important;
          }
        }

        &:hover, &.active {
          background: $primary-lighten-3 !important;
          @include shadowDefaultDarken1;
        }

        td {
          padding: 7px 10px 3px 10px;

          &:first-of-type {
            padding-left: 20px;
          }

          &:last-of-type {
            padding-right: 20px;
          }

          &.text-right {
            input[type="text"] {
              text-align: right;
            }
          }

          input[type="text"] {
            padding: 0;
            background: transparent;
            min-width: 0;
            max-width: 100% !important;
            width: 100%;
            border-width: 0 0 2px;
            border-color: transparent;
            border-style: solid;
            border-radius: 0;
            cursor: pointer;
            text-overflow: ellipsis;

            &:hover, &:focus {
              border-color: $primary-lighten-2;
            }

            &[readonly], &[disabled] {
              &:hover, &:focus {
                border-color: transparent;
              }
            }

            &[readonly] {
              cursor: default;


            }

            &[disabled] {
              cursor: not-allowed;
            }
          }

          .chosen-container {
            display: inline-block;
            width: auto !important;
            border-width: 0 0 2px;
            border-color: transparent;
            border-style: solid;

            &:hover {
              border-color: $dimmed-lighten;
            }

            &-active {
              border-color: transparent !important;
            }

            .chosen-single {
              padding: 0;
              margin-bottom: 0;
              border: 0 !important;
              border-radius: 0;
              background: transparent;
              height: 23px;
              line-height: 20px;
              display: inline;

              span {
                margin: 0;
              }

              > div {
                display: none;
              }
            }

            .chosen-drop {
              border-radius: 4px;

              .chosen-results {
                li {
                  &:first-of-type {
                    border-radius: 3px 3px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__details-double {
    display: flex;
    align-items: center;
    height: 100%;

    .table-advanced__detail + .table-advanced__detail {
      margin-left: 6px;
    }
  }

  &__detail {
    display: block;
    font-size: 1.143em;

    > span {
      display: block;
      padding-bottom: 3px;

      &.span-input {
        padding-bottom: 2px;
      }
    }

    &, input[type="text"] {
      font-weight: bold;
    }

    &--small {
      &, input[type="text"], .chosen-container .chosen-single span {
        font-weight: bold;
        font-size: 1em;
        color: $grey;
      }

      input[type="text"] {
        height: 22px;
      }

      input[type="text"], .chosen-container .chosen-single span {
        &:hover, &:focus {
          color: $dark !important;
        }
      }
    }

    &--lighten {
      &, input[type="text"], .chosen-container .chosen-single span {
        color: $grey;
      }

      input[type="text"], .chosen-container .chosen-single span {
        &:hover, &:focus {
          color: $dark !important;
        }
      }
    }

    &--inline {
      display: inline-block;
    }

    &--deleted {
      text-decoration: line-through;
    }
  }

  &__cell {
    &--order {
      width: 45px;

      @media(min-width: $media-xxl-width) {
        width: 50px;
      }
    }

    &--unit {
      width: 80px;

      @media(min-width: $media-xxl-width) {
        width: 85px;
      }
    }

    &--price {
      width: 90px;

      @media(min-width: $media-xxl-width) {
        width: 100px;
      }
    }

    &--sum {
      width: 100px;

      @media(min-width: $media-xxl-width) {
        width: 110px;
      }
    }

    &--actions {
      &--single {
        width: 40px;
      }
    }
  }

  &__actions {
    a {
      color: $grey;

      &:hover {
        color: $dimmed;
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 10;
    background: rgba(255, 255, 255, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 0.2s ease;
    cursor: not-allowed;

    &__wrapper {
      display: table;
      width: 100%;
      height: 100%;
    }

    &__inner {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;

      > div {
        padding: 15px;
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }
    }

    &:hover {
      opacity: 0.1;
    }
  }
}
