@mixin shadowDanger {
  box-shadow: 0 0 25px rgba(238, 83, 79, 0.75);
}

@mixin shadowSuccess {
  box-shadow: 0 0 25px rgba(167, 219, 78, 0.75);
}

@mixin shadowInfo {
  box-shadow: 0 0 25px rgba(47, 130, 202, 0.75);
}

@mixin shadowWarning {
  box-shadow: 0 0 25px rgba(255, 203, 41, 0.75);
}

@mixin shadowDefault {
  box-shadow: $shadow-default;
}

@mixin shadowDefaultDarken1 {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.17);
}

@mixin shadowDefaultDarken2 {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.37);
}

@mixin shadowDefaultDarken3 {
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.57);
}
