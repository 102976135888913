.table-a {
    width: 100%;
    position: static;

    * {
        //position: static;
    }

    thead {
        th {
            padding: 3px 8px;
            border-top: 2px #cdd5e3 solid;
            border-bottom: 2px #cdd5e3 solid;
            text-transform: uppercase;
            font-size: 0.9em;
            color: #76839A;
            height: 40px;

            .checkbox {
                margin-top: 7px;
                margin-bottom: 0;

                label {
                    font-weight: bold;
                }
                input[type="checkbox"] {
                    margin-top: 2px;
                }
            }

            &.filter {
                cursor: pointer;
                color: #76839A;

                &.active {
                    color: #31353C;

                    &:after {
                        display: inline-block;
                        position: relative;
                        top: -2px;
                        content: ' ';
                        height: 0;
                        width: 0;
                        margin-left: 5px;
                    }
                }

                &.desc {
                    &:after {
                        border-left: 4px transparent solid;
                        border-right: 4px transparent solid;
                        border-top: 4px #31353C solid;
                    }
                }

                &.asc {
                    &:after {
                        border-left: 4px transparent solid;
                        border-right: 4px transparent solid;
                        border-bottom: 4px #31353C solid;
                    }
                }
            }

            &.more-padding {
                padding-left: 13px;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 6px 8px;
                border-bottom: 1px #cdd5e3 solid;
                position: relative;

                i.no-data {
                    color: #ddd;
                }

                img {
                    border: 1px #eee solid;
                }

                > input {
                    border-radius: 15px !important;
                    border: 1px transparent solid !important;
                    background: transparent;
                    padding: 4px 5px;
                    width: 100%;

                    &.field-with-helper {
                        margin-bottom: 7px
                    }
                }

                > .chosen-container-single {
                    .chosen-single {
                        background: transparent;
                        border-radius: 15px !important;
                        height: 27px;
                        line-height: 25px;
                        border-color: transparent !important;

                        span {
                            margin-right: 18px;
                            line-height: 26px;
                        }

                        div {
                            b {
                                background-position-y: 4px !important;
                                display: none !important;
                            }
                        }
                    }
                }

                .field-helper {
                    padding: 1px 2px;
                    font-size: 0.8em;
                    line-height: 1em;
                    color: #aaa;
                    background: inherit;
                    top: 25px;
                    margin-left: 3px;
                    display: block;
                    position: absolute;
                    border-radius: 5px;
                    border: 1px transparent solid;
                }

                > a, > * > a {
                    color: #323740;

                    &:hover {
                        color: #5B5693;
                    }
                }

                &.actions {
                    text-align: right;

                    > div {
                        white-space: nowrap;

                        > span {
                            border-right: 1px #e0e5ee solid;

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }

                    > a, > div > a, > div > span > a {
                        padding: 0 4px;
                        border-right: 1px #e0e5ee solid;
                        color: #b8babe;
                        display: inline-block;
                        width: 24px;
                        font-size: 15px;
                        cursor: pointer;
                        text-align: center;

                        &:last-of-type {
                            border-right: 0;
                        }
                        &:hover {
                            opacity: 1;
                            color: #4d535d;
                        }
                    }
                }
            }

            &.error {
                td {
                    background: #f2dede !important;
                }
            }

            &.highlight {
                td {
                    background: #ee534f !important;
                    font-weight: bold;
                    color: #fff !important;

                    .value-positive, .value-negative {
                        color: #fff;
                    }

                    a {
                        color: #fff;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.actions {
                        > a, > div > a, > div > span > a {
                            border-right-color: #f6a9a7;
                            color: #f6a9a7;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }

            &.highlight-orange {
                td {
                    background: #EE984F !important;
                    font-weight: bold;
                    color: #fff !important;

                    .value-positive, .value-negative {
                        color: #fff;
                    }

                    a {
                        color: #fff;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.actions {
                        > a, > div > a, > div > span > a {
                            border-right-color: #FFCDA2;
                            color: #FFCDA2;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }

            &.greyed {
                opacity: 0.3;
                font-style: italic;

                &:hover {
                    opacity: 0.6
                }
            }

            &.active {
                opacity: 1 !important;
                font-style: normal !important;
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
            }

            &.clickable {
                cursor: pointer;
            }

            &.details {
                td {
                    background: #40454f !important;
                    color: #fff;
                    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5) inset;

                    .wrapper {
                        padding: 15px;
                    }
                }
            }
        }
        tr:nth-of-type(2n) {
            td {
                background: #f8f8f8;

                &.more-padding {
                    padding-left: 13px;
                }
            }
        }
        tr:hover, tr.hovered {
            td {
                background: #fdf6ff;

                input:not([disabled]), .field-helper, .chosen-container-single .chosen-single {
                    border-color: #ddd !important;
                }

                .chosen-container-single {
                    .chosen-single b {
                        display: block !important;
                    }
                }
            }
        }

        tr.active {
            td {
                color: $white !important;
                @extend %gradient-violet-important;

                i {
                    color: $white !important;
                }
            }
        }

        tr.clickable {
            td {
                cursor: pointer;
            }
        }

        tr:last-of-type {
            td {
                // border-bottom: 0;
            }
        }
        tr.trashed {
            opacity: 0.5;
        }
    }
    tfoot {
        tr {
            td {
                padding: 8px;
                border-top: 1px #cdd5e3 solid;
                color: #76839A;
            }
        }
        tr:first-of-type td {
            border-top: 2px #cdd5e3 solid;
        }
        tr:last-of-type td {
            border-top: 2px #cdd5e3 solid;
            border-bottom: 2px #cdd5e3 solid;
        }
    }
}

.table-a.a {
    tbody {
        td {
            padding: 3px 8px;
        }
    }
}

.table-a.table-a-double-rows {
    tbody {
        tr {
          td {
            background: #fff;
          }
        }

        &:nth-of-type(2n) {
            tr {
                td {
                    background: #f8f8f8;
                }
            }
        }

        &:hover {
            tr {
                td {
                    background: #fdf6ff;

                    input:not([disabled]), .field-helper, .chosen-container-single .chosen-single {
                        border-color: #ddd !important;
                    }

                    .chosen-container-single {
                        .chosen-single b {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
}

.table-b {
    width: 100%;
    position: static;
    color: #fff;

    thead {
        th {
            padding: 3px 8px;
            border-top: 2px #cdd5e3 solid;
            border-bottom: 2px #cdd5e3 solid;
            text-transform: uppercase;
            font-size: 11px;
            color: #76839A;
            height: 40px;
        }
    }

    tbody {
        tr {
            td {
                padding: 6px 8px;
                border-bottom: 1px #cdd5e3 solid;
            }

            &:last-of-type {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
}

.table-overview {
    overflow: overlay;
}

.online-dot {
    display: block;
    width: 6px;
    height: 6px;
    background: #bbb;
    border-radius: 100%;
}

.online-dot.on {
    background: #A8D467;
}

.table-a tr td .checkbox, .table-a tr td .radio {
    margin-top: 4px;
    margin-bottom: 0;
}

.table-tools {
    &:after {
        display: block;
        clear: both;
        content: ' ';
    }

    margin-bottom: 10px;

    .left {
        float: left;

        > * {
            margin-right: 10px !important;
            float: left;
        }

        @media(max-width: $media-md-width) {
            float: none;
            width: 100%;
        }
    }

    .right {
        float: right;

        > * {
            margin-left: 10px !important;
            float: left;

            @media(max-width: $media-md-width) {
                margin-left: 0 !important;
            }
        }
    }

    .counter {
        margin: 7px 0
    }

    .pagination-limits {
        width: 100px;

        select.form-control {
            background: #eee;
            border-color: transparent;
            border-radius: 15px;
            box-shadow: none;
        }
        select.form-control:focus {
            outline: none !important;
            box-shadow: none !important;
            background: #fff;
            border-color: #eee;
        }

        @media(max-width: $media-md-width) {
            width: 50%;
            margin-bottom: 5px;
        }
    }

    .pagination {
        margin: 0;

        @media(max-width: $media-md-width) {
            margin-left: 10px !important;
        }

        .btn {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    .search-form {
        @media(max-width: $media-md-width) {
            margin-bottom: 1px;
        }

        .input-group-addon {
            border-radius: 0 15px 15px 0;
            border-color: #e5e5e5;
            padding-top: 0px;
            padding-bottom: 0px;

            &:last-child {
                border-left-width: 1px;
            }
        }

        .clear-field {
            position: absolute;
            top: 11px;
            right: 48px;
            color: #ccc;
            z-index: 3;
            cursor: pointer;

            &:hover {
                color: #aaa;
            }
        }

        .form-control {
            border-radius: 15px 0 0 15px;
            width: 200px;
            border-color: #e5e5e5;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-right: 27px;
            border-right: 0;

            @media(max-width: $media-md-width) {
                width: 100%;
            }
        }
        .form-control:focus {
            background: #fff;
            border-color: #e5e5e5;
        }

        .btn {
            margin-left: 10px;
        }

        .refresh-btn {
            @media(max-width: $media-md-width) {
                margin-left: 0;
            }
        }
    }
}

.counter {
    margin: 5px 0;
    font-weight: bold;
}

.table-wrapper {
    position: relative;
}

.table-overlay {
    position: absolute;
    z-index: 10;
    background: rgba(255, 255, 255, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .wrapper {
        display: table;
        width: 100%;
        height: 100%;

        .inner {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            font-weight: bold;

            > div {
                padding: 15px;
                display: inline-block;
                border-radius: 5px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            }
        }
    }
}

.table-loading {
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    background-color: rgba(255, 255, 255, 0.8);
    background-image: url('/assets/img/loader-dark.gif');
    background-repeat: no-repeat;
    background-position: center 50px;
    background-size: 110px 10px;
    z-index: 10;
}

.table-overview {
    .table-overview-tools {
        .search-box {
            margin: 20px 0;
            height: 28px;
            width: calc(100% - 150px);
            float: left;
        }

        .counter {
            line-height: 32px;
            margin-right: 10px;
            margin: 18px 10px 18px 0;
        }
    }
}

.col-dot {
    width: 30px;
}

.col-count {
    width: 50px;
}

.col-photo {
    width: 41px;
}

.col-number {
    width: 130px;
}

.col-number-foreign {
    width: 150px;
}

.col-number-with-badge {
    width: 190px;
}

.col-contractor {
    width: 150px;
}

.col-category {
    width: 150px;
}

.col-code {
    width: 150px;
}

.col-worker {
    width: 150px;
}

.col-amount {
    width: 80px;
}

.col-amount-to-pay {
    width: 90px;
}

.col-documents-amount {
    width: 110px;
}

.col-date {
    width: 90px;
}

.col-datetime {
    width: 130px;
}

.col-payment {
    width: 105px;
}

.col-settled {
    width: 35px;
}

.col-warning {
    width: 35px;
}

.col-details {
    width: 120px;
}

.col-status {
    width: 180px;
}

.col-comments {
    width: 180px;
}

.col-actions {
    width: 115px;
}

.col-actions-single {
    width: 50px;
}

.col-actions-double {
    width: 80px;
}

.col-actions-triple {
    width: 115px;
}

.col-actions-quadruple {
    width: 150px;
}

@media screen and (min-width: 1600px) {
    .col-count {
        width: 70px;
    }
    .col-contractor {
        width: 180px;
    }
    .col-number {
        width: 120px;
    }
    .col-number-foreign {
        width: 150px;
    }
    .col-number-with-badge {
        width: 220px;
    }
    .col-worker {
        width: 180px;
    }
    .col-amount {
        width: 80px;
    }
    .col-amount-to-pay {
        width: 100px;
    }
    .col-date {
        width: 110px;
    }
    .col-datetime {
        width: 150px;
    }
    .col-payment {
        width: 125px;
    }
    .col-settled {
        width: 35px;
    }
    .col-warning {
        width: 35px;
    }
    .col-details {
        width: 150px;
    }
    .col-status {
        width: 210px;
    }
    .col-actions {
        width: 115px;
    }
    .col-actions-double {
        width: 130px;
    }
    .col-actions-triple {
        width: 160px;
    }
    .col-actions-quadruple {
        width: 180px;
    }
}
