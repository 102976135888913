.sweet-alert {
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
	max-height: calc(100% - 30px);
	overflow-y: auto;

	h2 {
		font-weight: 500;
	}

	button {
		border: 0;
		border-radius: $border-radius;
	    font-weight: 100;
	    text-transform: uppercase;
	    font-size: 12px;
	    box-shadow: none !important;
	    margin-left: 0;
	    margin-right: 0;
	}

	button.cancel {
		background: #eee;
		color: #333;
	}

	button.cancel:focus {
		box-shadow: none !important;
	}

	button.cancel:hover {
		background: #ddd;
	}
}

.pin-confirmation {
	input {
		width: auto;
		font-size: 48px;
	    height: auto;
	    border: 1px #ddd solid !important;
    	border-right: 0 !important;
    	border-left: 0 !important;
    	border-radius: 0;
	    letter-spacing: 30px;
	    padding-left: 160px;
	    margin: 0 -17px;
		-webkit-text-security: disc;
	}
}

.modal-on {
	overflow: hidden;
}

.modal-enter, .modal-leave {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.product-image { border-radius: 5px; border: 1px #eee solid; }
