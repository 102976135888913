.color-violet { color: #5B5693; }

.float-none { float: none !important; }

.no-decoration {
	text-decoration: none !important;

	&:hover {
		text-decoration: underline !important;
	}
}

.font-100 { font-weight: 100; }

.rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    transform: rotate(180deg)
}

.image-rounded {
    border: 1px #ddd solid;
    border-radius: 7px;
    padding: 3px;

    img {
        border-radius: 5px;
    }
}

.badge {
    background: #5B5693;

  &.gold {
    background: #c0a772;
  }

  &.teal {
    background: #006D77;
  }
}

.pagination {
    margin: 5px 0;
}

.cursor-pointer { cursor: pointer; }
.cursor-default { cursor: default; }

.position-relative { position: relative; }

.clear-space { clear: both; }

.fa-chevron-left + .fa-chevron-left { margin-left: -5px; }
.fa-chevron-right + .fa-chevron-right { margin-left: -5px; }

.d-flex { display: flex; align-items: center; flex-wrap: wrap; }

.mt0{ margin-top: 0 !important }
.mt5{ margin-top: 5px !important }
.mt7{ margin-top: 7px !important }
.mt10{ margin-top: 10px !important }
.mt15{ margin-top: 15px !important }
.mt20{ margin-top: 20px !important }
.mt30{ margin-top: 30px !important }

.mtn5{ margin-top: -5px !important }
.mtn10{ margin-top: -10px !important }
.mtn15{ margin-top: -15px !important }
.mtn20{ margin-top: -20px !important }
.mtn30{ margin-top: -30px !important }

.ml0{ margin-left: 0 !important }
.ml5{ margin-left: 5px !important }
.ml10{ margin-left: 10px !important }
.ml15{ margin-left: 15px !important }
.ml20{ margin-left: 20px !important }
.ml30{ margin-left: 30px !important }

.mr0{ margin-right: 0 !important }
.mr5{ margin-right: 5px !important }
.mr10{ margin-right: 10px !important }
.mr15{ margin-right: 15px !important }
.mr20{ margin-right: 20px !important }
.mr30{ margin-right: 30px !important }

.mb0{ margin-bottom: 0px !important; }
.mb5{ margin-bottom: 5px !important; }
.mb10{ margin-bottom: 10px !important; }
.mb15{ margin-bottom: 15px !important; }
.mb20{ margin-bottom: 20px !important; }
.mb30{ margin-bottom: 30px !important; }

.pt0{ padding-top: 0 !important }
.pt2{ padding-top: 2px !important }
.pt5{ padding-top: 5px !important }
.pt10{ padding-top: 10px !important }
.pt15{ padding-top: 15px !important }
.pt20{ padding-top: 20px !important }
.pt30{ padding-top: 30px !important }

.pl0{ padding-left: 0px !important; }
.pl5{ padding-left: 5px !important; }
.pl10{ padding-left: 10px !important; }
.pl15{ padding-left: 15px !important; }
.pl20{ padding-left: 20px !important; }
.pl30{ padding-left: 30px !important; }

.pb0{ padding-bottom: 0px !important; }
.pb5{ padding-bottom: 5px !important; }
.pb10{ padding-bottom: 10px !important; }
.pb15{ padding-bottom: 15px !important; }
.pb20{ padding-bottom: 20px !important; }
.pb30{ padding-bottom: 30px !important; }

.fleft { float: left; }
.fright { float: right; }

.font-14 { font-size: 14px; }
.font-16 { font-size: 16px; }
.font-18 { font-size: 18px; }

.d-block {
  display: block !important;
}
