.ui-datepicker {
	background-color: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
	display: none;
	margin-top: 4px;
	padding: 10px;
	width: 240px;
}
.ui-datepicker a,
.ui-datepicker a:hover {
	text-decoration: none;
	color: #4D535D;
}
.ui-datepicker a:hover,
.ui-datepicker td:hover a {
	
}
.ui-datepicker .ui-datepicker-header {
	margin-bottom: 4px;
	text-align: center;
}
.ui-datepicker .ui-datepicker-title {
	font-weight: 700;
	background: linear-gradient(to bottom, #8F8BBB 0%, #5B5693 100%);
	padding: 10px;
	margin: -10px -10px 5px;
	color: #fff;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	font-style: normal;
	font-weight: normal;
	height: 37px;
	line-height: 37px;
	width: 30px;
	z-index: 1;
	cursor: pointer;
	color: #fff;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
	text-decoration: underline;
	color: #fff;
}
.ui-datepicker .ui-datepicker-prev {
	float: left;
	text-align: left;
}
.ui-datepicker .ui-datepicker-next {
	float: right;
	text-align: right;
}
.ui-datepicker .ui-datepicker-calendar {
  table-layout: fixed;
	width: 100%;
}
.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
	text-align: center;
	padding: 4px 0;
}
.ui-datepicker .ui-datepicker-calendar td {
	border-radius: 4px;
	-webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
	   -moz-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
	     -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
	        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.ui-datepicker .ui-datepicker-calendar td:hover {
	background-color: #eee;
	color: #232323 !important;
	cursor: pointer;
}
.ui-datepicker .ui-datepicker-calendar td a {
	text-decoration: none;
}
.ui-datepicker .ui-datepicker-current-day {
	background-color: #232323;
}
.ui-datepicker .ui-datepicker-current-day a {
	color: #fff
}
.ui-datepicker .ui-datepicker-today {
	background-color: #CDACD8;
}
.ui-datepicker .ui-datepicker-today a {
	color: #fff
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
	background-color: #fff;
	cursor: default;
}