.pie-chart-wrapper {
	padding: 0 50px 30px;
	max-width: 300px;
	margin: auto;
}

.chart-wrapper {
	background: #31353c;
	padding: 20px 25px 10px;
	border-radius: 5px;

	.chart-legend {
		* {
			color: #fff;
		}
	}

	.heading {
	    color: #fff;
	    margin: 10px 0 15px 0;
	    padding-bottom: 15px;
	    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

	    &.no-border {
	    	border: 0;
	    	padding-bottom: 0;
	    }

	    * {
    	    margin: 0;
    		font-weight: 100;
	    }
	}

	.row {
		margin-left: -25px;
		margin-right: -25px;

		> div {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}

// .hoursChartWrapper, .hoursChartWrapper > div {
// 	height: 200px;
// 	width: 100%;
// }

// .hoursChartWrapper iframe {
// 	height: 100% !important;
// 	width: 100% !important;
// }

// #hoursChart {
// 	height: auto !important;
// 	width: auto !important;
// }

.chart-legend {
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 1em;

	li {
		padding: 0 0 0 25px;
		margin: 3px 0;

		.a {
			float: left;
			padding-right: 15px;
		}
		.b {
			float: right;
			font-weight: bold;
		}

		.color {
			display: block;
			content: ' ';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 15px;
			height: 15px;
			background: #fff;
		}

		&:after {
			display: block;
			clear: both;
			content: ' ';
		}
	}
}
