@import './../settings';

.layout {
    min-height: 100%;
    position: static;

    .layout-header {
        padding: 25px 25px 0 25px;
        line-height: 1;

        transition: left 0.4s ease 0s;
        -moz-transition: left 0.4s ease 0s;
        -webkit-transition: left 0.4s ease 0s;
        -o-transition: left 0.4s ease 0s;
        -ms-transition: left 0.4s ease 0s;

        .inner {
            float: left;

            @media (max-width: $media-sm-width) {
                float: none;
            }

            h1 {
                font-size: 2em;
                font-weight: bold;
                margin: 4px 0 0;

                small {
                    text-transform: none;
                    font-size: 0.65em;
                    color: rgb(170, 170, 170);
                    font-weight: 500;

                    @media (max-width: $media-sm-width) {
                        display: block;
                    }
                }
            }
        }

        .buttons {
            float: right;
            line-height: 32px;

            @media (max-width: $media-sm-width) {
                float: none;
                margin-top: 10px;
            }

            .button-label {
                font-size: 34px;
                vertical-align: top;
                line-height: 0;
                height: 34px;
                display: inline-block;

                &.success {
                    color: #82B13D;
                }
            }

            h3 {
                display: inline-block;
                vertical-align: middle;
                margin: 0 10px 0 0;
            }

            .button-label {
                font-size: 34px;
                vertical-align: top;
                line-height: 0;
                height: 34px;
                display: inline-block;

                &.success {
                    color: #82B13D;
                }
            }
        }

        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }

    .layout-sidebar {
        width: 280px;
        background: #fff;
        border-right: 1px #eee solid;
        border-top-left-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        .wrapper {
            overflow-y: auto;
            height: calc(100% - #{$header-height});
            width: 280px;
            position: fixed;

            .inner {
                padding: 25px 15px 15px 15px;
            }
        }
    }

    .layout-wrapper {
        width: calc(100% - 280px);
        height: 100%;
        float: right;
    }

    .layout-content {
        padding: 25px;
    }

    &:after {
        clear: both;
        content: ' ';
        display: block;
    }
}

.layout-header-fixed {
    .layout:not(.in-modal) {
        padding-top: 58px;

        > .layout-header {
            position: fixed;
            top: 0;
            right: 0;
            left: $sidebar-width;
            z-index: 10;
            padding-top: 10px;
            padding-bottom: 10px;
            background: #fff;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);

            > .buttons {
                @media (max-width: $media-sm-width) {
                    display: none;
                }
            }
        }
    }

    .default-layout.with-collapsed-sidebar {
        .layout:not(.in-modal) {
            > .layout-header {
                left: $sidebar-collapsed-width;

                @media (max-width: $media-sm-width) {
                    left: 0 !important;
                }
            }
        }
    }
}

.layout-header-fixed.modal-on {
    .layout:not(.in-modal) {
        > .layout-header {
            opacity: 0;
        }
    }
}

@media (min-width: 1600px) {
    .layout-header-fixed {
        .layout:not(.in-modal) {
            > .layout-header {
                left: $sidebar-xl-width;
            }
        }
    }

    .layout-header-fixed {
        .default-layout.with-collapsed-sidebar {
            .layout:not(.in-modal) {
                .layout-header {
                    left: $sidebar-collapsed-width;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .layout-header-fixed {
        .layout:not(.in-modal) {
            > .layout-header {
                left: 0;
            }
        }
    }

    .layout {
        .layout-wrapper {
            float: none;
            width: auto;
        }

        .layout-sidebar {
            display: none;
        }
    }
}

