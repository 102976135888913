@import './../settings';

.tooltip {
    .tooltip-inner {
        padding: 8px 12px;
        border-radius: 10px;
        font-weight: bold;
        background-color: $sidebar-hover-background;
    }

    &.top {
        .tooltip-arrow {
            border-top-color: $sidebar-hover-background;
            border-width: 5px 8px 0 8px;
            margin-left: -8px;
        }
    }

    &.bottom {
        .tooltip-arrow {
            border-bottom-color: $sidebar-hover-background;
            border-width: 0 8px 5px 8px;
            margin-left: -8px;
        }
    }

    &.left {
        .tooltip-arrow {
            border-left-color: $sidebar-hover-background;
            border-width: 8px 0 8px 5px;
            margin-top: -8px;
        }
    }

    &.right {
        .tooltip-arrow {
            border-right-color: $sidebar-hover-background;
            border-width: 8px 5px 8px 0;
            margin-top: -8px;
        }
    }

    &.in {
        opacity: 1;
    }
}

.app-screen {
    .tooltip-inner {
        max-width: 500px;
        width: 300px;
    }

    .tooltip-inner ul {
        padding-top: 10px;
        padding-right: 25px;
        padding-left: 25px;
    }

    .tooltip-inner li {
        font-size: 14px;
        line-height: 25px;
        text-align: left;
    }
}
