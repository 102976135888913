@import 'colors';

@import 'mixins/gradients';
@import 'mixins/shadows';

$media-xxl-width: 1600px;
$media-xl-width: 1200px;
$media-lg-width: 991px;
$media-md-width: 767px;
$media-sm-width: 440px;

$main-dark-background: #282c34;
$main-background: #f7f8f8;
$white: #fff;

$text-black: #333;

$header-height: 46px;
$header-height-md: 40px;

$border-color: #eee;

$border-radius: 4px;

$sidebar-width: 260px;
$sidebar-xl-width: 260px;
$sidebar-collapsed-width: 49px;

$sidebar-hover-background: #383c45;
$sidebar-borders-color: #4D535D;

$shadow-default: 0 0 25px rgba(0, 0, 0, 0.07);

%gradient-violet {
    background: #8F8BBB;
    background: -moz-linear-gradient(45deg,  #8F8BBB 0%, #B38BBB 100%);
    background: -webkit-linear-gradient(45deg,  #8F8BBB 0%,#B38BBB 100%);
    background: linear-gradient(45deg,  #8F8BBB 0%,#B38BBB 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8F8BBB', endColorstr='#B38BBB',GradientType=45 );
}

%gradient-violet-important {
    background: #8F8BBB !important;
    background: -moz-linear-gradient(45deg,  #8F8BBB 0%, #B38BBB 100%) !important;
    background: -webkit-linear-gradient(45deg,  #8F8BBB 0%,#B38BBB 100%) !important;
    background: linear-gradient(45deg,  #8F8BBB 0%,#B38BBB 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8F8BBB', endColorstr='#B38BBB',GradientType=45 ) !important;
}
