input {
    box-shadow: none !important;
}

input:focus, textarea:focus, button:focus {
    outline: none !important;
    box-shadow: none !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: rgb(51, 51, 51) !important;
}

textarea {
    resize: none;
}

.form-group {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.form-control {
    font-size: 1em;
    padding: 4px 6px;
    height: 30px;
    color: #333 !important;
    border-color: #d5d5d5;

    &.form-control-large {
        font-size: 16px;
        padding: 10px;
        height: 50px;
    }

    @media(min-width: 1500px) {
        height: 34px;
    }
}

.control-label.col-sm-12 {
    margin-bottom: 5px;
}

.disable-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.6
}

.ms-ctn {
    height: auto !important;
}

.input-static {
    margin: 10px 0;
}

.input-loading:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 11px;
    right: 21px;
    width: 14px;
    height: 14px;
    background: url('/assets/img/loader-mini.gif') no-repeat center;
    background-size: cover;
}

.input-group {
    &.input-loading:after {
        z-index: 5;
        right: 50px;
    }

    .input-group-btn {
        .btn {
            margin-top: 0 !important;
        }
    }

    .form-control {
        height: 34px;
    }

    :first-child {
        .btn {
            border-radius: 4px 0 0 4px;
        }
    }

    :last-child {
        .btn {
            border-radius: 0 4px 4px 0;
        }
    }

    label {
        margin-bottom: 0;
    }
}

.chosen-container-single .chosen-single, .chosen-container-single .chosen-choices {
    border-color: #d5d5d5 !important;
}

.row-fields {
    margin-left: -5px;
    margin-right: -5px;

    > * {
        padding-left: 5px;
        padding-right: 5px;

        margin: auto !important;
    }
}

.row-fields + .row-fields {
    margin-top: 10px;
}

.input-group-addon {
    font-size: 0.912em;
}

.form-control + .input-group-addon, .input-group-addon + .input-group-addon {
    border-left: 0;
}

a.input-group-addon {
    cursor: pointer;
    border: 0;
    font-size: 1em;
}

.input-group-addon.btn-primary {
    color: #fff;
}

.input-group-addon:first-child {
    border-radius: 5px 0 0 5px;
}

.input-group-addon:last-child {
    border-radius: 0 5px 5px 0;
}

.has-error {
    label {
        color: #a94442;

        .asterisk {
            color: #a94442;
        }

        .info {
            display: block;
            font-weight: normal;
            color: #aaa;
        }
    }

    input {
        background: #f2dede;
        border-color: #ebccd1 !important;
    }

    .chosen-single {
        background: #f2dede;
        border-color: #ebccd1 !important;
    }

    .chosen-choices {
        background: #f2dede;
        border-color: #ebccd1 !important;
    }

    .input-group-addon {
        border-color: #ebccd1 !important;
    }
}

a.cke_dialog_tab_selected:focus {
    text-decoration: none;
}

a.cke_dialog_ui_button_ok {
    background: #5B5693 !important;
    border: 0 !important;
}

a.cke_dialog_ui_button_ok:hover {
    background: #6C2F80 !important;
}

a.cke_dialog_ui_button {
    border-radius: 15px !important;
}

.cke_dialog_background_cover {
    background: #000 !important;
}

.cke_dialog_body {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}

.cke_dialog_contents_body {
    min-width: 382px;
}

.daterangepicker-input {
    border-radius: 4px;

    &.daterangepicker-input-header {
        border-radius: 0;
        border: 0;
        background: transparent;
        text-align: right;
        font-size: 1.5em;
        max-width: 300px;
        font-weight: bold;
        cursor: pointer;
        padding: 5px 0 0 0;
        float: right;

        @media(max-width: $media-sm-width) {
            max-width: none;
            width: 100%;
            text-align: left;
            background: $white;
            border: 1px $border-color solid;
            border-radius: $border-radius;
            padding: 10px;
            height: auto;
        }
    }
}

.daterangepicker.opensright:before {
    right: 29px;
    left: auto !important;
}

.daterangepicker.opensright:after {
    right: 30px;
    left: auto !important;
}

.daterangepicker .ranges {
    float: right;
}

.login-page {
    margin-left: 0 !important;
    background: #8F8BBB;
    background: linear-gradient(45deg, #8F8BBB 0%,#B38BBB 100%);

    .fit-a {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.2;
        z-index: 1;
    }

    form {
        max-width: 320px;
        margin: 150px auto 15px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 10px;
        box-shadow: 0 0 35px rgba(64, 60, 104, 0.3);

        img {
            margin-bottom: 15px;
        }

        hr {
            margin: 0 -20px;
        }

        .form-group {
            margin: 0;
        }

        i {
            position: absolute;
            top: 22px;
            left: 0px;
            z-index: 2;
        }

        input {
            background: none;
            padding: 15px 20px 15px 45px;
            height: 58px;
            margin: 0 -20px;
            width: calc(100% + 30px);
            border-radius: 0;
            border: 0;
        }

        ::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
            text-transform: uppercase;
        }
        .list-unstyled {
            margin-bottom: 0;
        }

        .alert {
            margin: 5px 0;
            padding: 5px 10px;
            font-size: 12px;
        }
    }
}

.chosen-container, .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    width: 100% !important;
}

.chosen-container-single .chosen-single abbr {
    top: 11px;
}

.chosen-container-single .chosen-results {
    margin: 0 0 4px;
    padding: 0;
    @include shadowDefault;
}

.chosen-container-single .chosen-results li:last-of-type {
    border-radius: 0 0 3px 3px;
}

.form-a {

    label {
        .asterisk {
            color: #5B5693;
        }

        .small-info, small:not(.asterisk) {
            color: #aaa;
            font-size: 10px;
            display: block;
            font-weight: 500;
        }
    }

    input[type="text"], input[type="password"], input[type="email"], textarea {
        border-radius: 4px;
        border-color: #d5d5d5;
        box-shadow: none;
        max-width: 100%;
    }
    input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, select:focus, textarea:focus {
        border-color: #CDACD8;
        box-shadow: none !important;
    }

    .form-column {
        margin-bottom: 15px;
        border-bottom: 1px #d5d5d5 dashed;
    }

    .control-label {
        text-align: left;
        cursor: pointer;
        line-height: 14px;
        padding-top: 10px;
    }

    .radio-inline, .checkbox-inline {
        line-height: 22px;
    }

    .help-block {
        margin-top: 1px;
        margin-bottom: 0;
        font-size: 12px;
    }
}

.radio-inline, .checkbox-inline {
    cursor: default;
}

.checkbox-a {
    padding: 0 10px 0 22px;
    position: relative;
    line-height: 16px !important;

    label {
        cursor: pointer;
        position: relative;
        text-align: justify;
        font-weight: normal;
        float: none;
        padding: 0;
        opacity: 1 !important;
    }
    input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
        margin-top: 0;
    }
    label:after {
        content: '';
        cursor: pointer;
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        position: absolute;
        top: 0;
        left: -22px;
        visibility: visible;
    }

    input[type="checkbox"]:checked + label:after {
        background: #8F8BBB;
        background: -moz-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
        background: -webkit-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
        background: linear-gradient(to bottom, #8F8BBB 0%, #5B5693 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8F8BBB', endColorstr='#5B5693', GradientType=0);
    }
    span.error {
        position: relative;
        top: auto;
        right: auto;
    }
}

.checkbox-a.has-error label:after {
    background: #f2dede;
    border-color: #ebccd1 !important;
}

.radio-a {
    padding: 0 12px 0 20px;
    margin-bottom: 20px;
    position: relative;
    line-height: 16px !important;

    label {
        cursor: pointer;
        position: relative;
        text-align: justify;
        font-weight: normal;
        width: 100%;
        float: none;
        padding: 0;
        opacity: 1 !important;
    }
    input[type="radio"] {
        visibility: hidden;
        position: absolute;
        margin-top: 0;
    }
    label:after {
        content: '';
        cursor: pointer;
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        position: absolute;
        top: 0;
        left: -20px;
        visibility: visible;
        border-radius: 100%;
    }
    input[type="radio"]:checked + label:after {
        border-color: transparent;
        background: #8F8BBB;
        background: -moz-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
        background: -webkit-linear-gradient(top, #8F8BBB 0%, #5B5693 100%);
        background: linear-gradient(to bottom, #8F8BBB 0%, #5B5693 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8F8BBB', endColorstr='#5B5693', GradientType=0);
    }
    input[type="radio"]:disabled + label:after {
        background: #d5d5d5 !important;
    }
    input[type="radio"]:checked:disabled + label:after {
        background: #777 !important;
    }
    input[type="radio"]:disabled + label, input[type="radio"]:disabled + label:after {
        cursor: not-allowed;
    }
    span.error {
        position: relative;
        top: auto;
        right: auto;
    }
}

.radio-a.has-error label:after {
    background: #f2dede;
    border-color: #ebccd1 !important;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-left: 0px;
}

.radios-buttons {
    margin-bottom: 15px;
    display: flex;

    > div {
        flex-grow: 1;

        input[type="radio"] {
            visibility: hidden;
            position: absolute;
            margin-top: 0;

            &:checked + label {
                color: #fff;
                background: #4d525a;
                background: linear-gradient(to bottom, #4d525a 0%, #323740 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d525a', endColorstr='#323740', GradientType=0)
            }
        }

        label {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 20px;
            color: #777;
            background: #eaeaea;
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            border-right: 1px #ddd solid;
            box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            &:hover {
                background: #f5f5f5;
            }
        }

        &:first-of-type label {
            border-radius: 4px 0 0 4px;
        }
        &:last-of-type label {
            border-radius: 0 4px 4px 0;
            border-right: 0;
        }

        &.disabled {
            label {
                opacity: 0.55;
                color: #aaa;
                text-decoration: line-through;
                cursor: not-allowed;
            }
        }
    }

    &.two > div {
        width: 50%;
    }
}

.radios-buttons.small {
    margin-bottom: 0;

    label {
        padding: 10px;
        font-size: 12px;
        text-transform: none;

        @media(min-width: $media-lg-width){
            font-size: 14px;
        }
    }
}

#files-uploader {
    .files-wrapper {
        height: 100px;
        border-radius: 10px;
        border: 3px #d5d5d5 dashed;
        background: #f5f5f5;
        width: 100%;
        overflow: hidden;
        position: relative;

        input {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            border: 300px solid transparent;
            opacity: 0;
            -ms-filter: 'alpha(opacity=0)';
            filter: alpha(opacity=0);
            -o-transform: translate(-300px, -300px) scale(10);
            -moz-transform: translate(-800px, 0) scale(10);
            cursor: pointer;
            height: 100%;
        }

        label {
            position: absolute;
            left: 50%;
            top: 40%;
            display: block;
            width: 200px;
            height: 30px;
            line-height: 16px;
            margin-left: -100px;
            margin-top: -15px;
            text-align: center;
            color: #888
        }
    }

    .files-wrapper.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    .files-wrapper:hover {
        label {
            color: #666;
        }
    }

    .files-wrapper:active {
        label {
            color: #666;
        }
    }
}

.files-container:after {
    content: ' ';
    clear: both;
    display: block;
}

.files-container {
    margin: 15px -4px 0 -4px;
    padding: 0;
    list-style: none;

    li {
        float: left;
        margin: 4px;
        padding: 0;
        width: 90px;
        height: 90px;
        display: block;
        border-radius: 7px;
        background: #f5f5f5 url('/assets/img/loader-dark.gif') no-repeat center center;
        background-size: 55px 5px;

        .inner {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 7px;
            border: 1px #d5d5d5 solid;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        .name {
            overflow: hidden;
            display: block;
            padding: 5px;
            width: 100%;
        }

        .progress {
            height: 100%;;
            width: 0;
            background: #5bb75b;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            z-index: 1;
        }

        .progress_percent {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            width: 100%;
            font-size: 12px;
            color: #fff;
            text-align: center;
            z-index: 2;
        }

        .delete {
            display: none;
            width: 16px;
            height: 16px;
            background: #6C2F80;
            color: #fff;
            font-size: 10px;
            line-height: 14px;
            text-align: center;
            position: absolute;
            font-weight: bold;
            top: -2px;
            right: -2px;
            border-radius: 5px;
            cursor: pointer;
            z-index: 2;
        }
        .delete:hover {
            text-decoration: none;
        }
    }

    li:hover {
        .delete {
            display: block;
        }
    }

    li.ui-sortable-helper {
        background: none;
        border: 0;
    }

    li.ui-sortable-helper * {
        position: absolute;
    }

    li.loading {
        overflow: hidden;

        .inner {
            overflow: hidden;
        }
    }

    li.uploaded {
        cursor: pointer;
        opacity: 0.7
    }
    li.uploaded:hover {
        opacity: 0.9;
    }

    li.error {
        background: #f2dede;

        .name {
            color: #a94442;
            font-size: 10px;
            line-height: 14px;
        }

        .progress, .progress_percent {
            display: none;
        }
    }

    li.placeholder {
        width: 90px;
        height: 90px;
        background: #f5f5f5;
        border: 3px #d5d5d5 dotted;
    }
}

#file-uploader {
    .file-wrapper {
        height: 150px;
        width: 150px;
        border-radius: 10px;
        border: 3px #d5d5d5 dashed;
        background: #fafafa;
        overflow: hidden;
        position: relative;

        input {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            border: 300px solid transparent;
            opacity: 0;
            -ms-filter: 'alpha(opacity=0)';
            filter: alpha(opacity=0);
            -o-transform: translate(-300px, -300px) scale(10);
            -moz-transform: translate(-800px, 0) scale(10);
            cursor: pointer;
            height: 100%;
        }

        label {
            position: absolute;
            left: 50%;
            top: 40%;
            display: block;
            width: 144px;
            height: 30px;
            line-height: 16px;
            margin-left: -72px;
            margin-top: -15px;
            text-align: center;
            color: #aaa;
            font-size: 11px;
        }

        label.error {
            color: #a94442;
        }
    }

    .file-wrapper.loading {
        opacity: 0.7;
        cursor: not-allowed;
        background: #f5f5f5 url('/assets/img/loader-dark.gif') no-repeat center center;
        background-size: 110px 10px;

        label {
            display: none;
        }
    }

    .file-wrapper:hover {
        label {
            color: #666;
        }
    }

    .file-wrapper:active {
        label {
            color: #666;
        }
    }

    .inner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .delete {
        display: block;
        width: 18px;
        height: 18px;
        background: #6C2F80;
        color: #fff;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        position: absolute;
        font-weight: bold;
        top: 3px;
        right: 3px;
        border-radius: 5px;
        cursor: pointer;
        z-index: 2;
    }
    .delete:hover {
        text-decoration: none;
    }
}

.autocomplete {
    z-index: 50;
    position: absolute !important;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    border-radius: 5px;
    width: 100%;
    max-height: 169px;
    min-width: 15%;
    margin-top: 1px;

    > ul {
        font-family: sans-serif;
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        width: 100%;

        li {
            a {
                text-decoration: none;
                display: block;
                background: #f8f8f8;
                border-bottom: 1px #ddd solid;
                color: #2b2b2b;
                padding: 10px 5px;
                padding-left: 10px;
                position: relative;
                cursor: pointer;

                &:hover {
                    background: #ddd;
                }

                &.greyed {
                    color: #888;
                }

                span {
                    display: block;
                    color: #999;
                    font-size: 11px;
                }

                .details {
                    position: absolute;
                    bottom: 3px;
                    right: 0;

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            display: inline-block;
                            margin-right: 15px;

                            span {
                                display: inline-block;

                                &.a {
                                    color: #999;
                                    font-size: 10px;
                                }

                                &.b {
                                    color: #333;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            &.focus-list a {
                background: #ddd;
            }

            &:last-of-type a {
                border-radius: 0 0 5px 5px;
                border: 0;
            }

            &.showAll-transition {
                opacity: 1;
                overflow: hidden;
            }

            &.showAll-enter {
                opacity: 0.3;
                height: 0;
            }

            &.showAll-leave {
                display: none;
            }

        }
    }

    &.autocomplete-products {
        max-height: 177px;

        > ul {
            li {
                position: relative;

                a {
                    height: 51px;
                    padding-left: 60px;
                }

                figure {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #eee;

                    img {
                        max-width: 100%;
                    }

                    &.add-new {
                        padding: 12px 0;
                        text-align: center;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.label-a {
    float: left;
    display: block;
    padding: 13px 10px 0 0;
    line-height: 1em;

    &.width-50 {
        width: 50px;
    }
    &.width-100 {
        width: 100px;
    }
    &.width-150 {
        width: 150px;
    }
}

.label-input-a {
    float: left;
    display: block;

    &.width-50 {
        width: calc(100% - 50px);
    }
    &.width-100 {
        width: calc(100% - 100px);
    }
    &.width-150 {
        width: calc(100% - 150px);
    }
}
