.swal2-popup.popup {
  font-size: 16px;
  padding-bottom: 0.75em;
  @include shadowDefaultDarken2;

  &__pin {
    padding-left: 0;
    padding-right: 0;

    input {
      font-size: 4em;
      height: auto;
      border: 1px #ddd solid;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      padding-left: 82px;
      letter-spacing: 30px;
      text-align: center;
      margin: 0;
    }
  }

  .swal2 {
    &-title {
      margin: 0.6em 0 1em;
      font-weight: 500;
    }

    &-actions {
      margin-top: .75em;

      button {
        margin: .3125em;
      }
    }
  }
}
